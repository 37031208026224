<template>
	<div>
		<div class="queryBox mb20 ">
			<div class="mb20">
				<el-button class="mr20" size="mini" type="primary" @click="newqr('new')">新增</el-button>
				<el-button class="mr20" size="mini" type="warning" @click="stateAll(0)"
					:disabled="disLoading || operating || tableData.length == 0 || multipleSelection.length == 0">批量停用
				</el-button>
				<el-button class="mr20" size="mini" type="success" @click="stateAll(1)"
					:disabled="disLoading || operating || tableData.length == 0 || multipleSelection.length == 0">批量启用
				</el-button>
				<el-button size="mini" type="danger" @click="deleteAll()"
					:disabled="disLoading || operating || tableData.length == 0 || multipleSelection.length == 0">批量删除
				</el-button>
			</div>
			<div class="flexX flexcenter">
				<span>状态：</span>
				<el-select size="mini" class="mr20" v-model="state" clearable placeholder="请选择状态">
					<el-option v-for="(item,index) in stateArr" :key="item.key" :label="item.val" :value="item.key">
					</el-option>
				</el-select>
				<span>金额：</span>
				<el-select size="mini" class="mr20" v-model="money" clearable placeholder="请选择金额">
					<el-option v-for="(item) in moneyArr" :key="item" :label="item" :value="item"></el-option>
				</el-select>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'"
					@click="getData" :disabled="disLoading">查询</el-button>
			</div>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" border="" stripe style="width: 100%" v-loading="loading"
				@selection-change="handleSelectionChange" size="mini" :show-overflow-tooltip="true">
				<el-table-column type="selection" align="center" width="55"></el-table-column>
				<el-table-column type="index" label="序号" align="center" width="120px">
					<template slot-scope="scope">
						<div class="errorDiv" v-if="scope.row.id == index" v-for="(item,index) in errorArr">{{item}}
						</div>
						<span>{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="money" label="金额" align="center"></el-table-column>
				<el-table-column label="启停状态" align="center">
					<template slot-scope="scope">
						<span
							:class="scope.row.state == 1?'csuccess':'cdanger'">{{scope.row.state == 1?'启用':'停用'}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="note" label="备注" align="center"></el-table-column>
				<el-table-column label="二维码链接" align="center" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						<div class="textCenter">
							<span>{{scope.row.qrcode_url}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="二维码" align="center">
					<template slot-scope="scope">
						<div class="textCenter">
							<span v-if='scope.row.qrcode_url' @click="showQr(scope.row.qrcode_url)"
								class="qrIcon"></span>
						</div>

					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="添加时间" align="center"></el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-link type="primary" class="mr20" @click="edit(scope.row)">编辑</el-link>
						<el-button :disabled="operating" :type="scope.row.state == 1?'warning':'success'" size="mini" @click="setState(scope.row)">{{scope.row.state == 1?'停用':'启用'}}</el-button>
						<el-button :disabled="operating" type="danger" size="mini" @click="deleteQr(scope.row)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</template>

		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog center class="dialog" top="150px" title="" :visible.sync="dialogVisible" @close="close" width="30%"
			:close-on-click-modal="closeModal" :title="dialogTitle">
			<div class="formBox" v-loading="dialogloading">				
				<div class="textCenter">
					<div>
						<div class="flexX flexcenter mb20">
							<span>金额：</span>
							<span>{{item.money}}</span>
						</div>
						<div class="flexX flexcenter mb20">
							<span>时间：</span>
							<span>{{item.add_time}}</span>
						</div>
						<div class="flexX flexcenter mb20">
							<span>备注：</span>
							<el-input size="mini" placeholder="请输入备注" style="width: 400px;" v-model="item.note"></el-input>
						</div>
					</div>
					<el-button size="mini" type="primary" @click="sure()">确定</el-button>
					<el-button size="mini" @click="close">关闭</el-button>
				</div>
			</div>
		</el-dialog>
		<div @click="closePopup()" class="popup" :class="{'show':showPopup}">
			<div id="qr" class="qrcode" ref="qrCodeUrl"></div>
		</div>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	import QRCode from 'qrcodejs2'
	export default {
		data() {
			return {
				tableData: [],
				height: 0,
				loading: false,
				disLoading: false,
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				state: '',
				stateArr: [{
						key: '',
						val: "全部"
					},
					{
						key: '1',
						val: "可使用"
					},
					{
						key: '2',
						val: "已使用"
					},
					{
						key: '3',
						val: "已停用"
					},
				],
				money: '',
				moneyArr: [],
				id: '',
				operating: false, //正在操作
				multipleSelection: [], //选中数组
				showPopup: false,
				errorArr: {},
				fileList:[],
				uploadData:{},
				upErrorArr:[],
				

				pay_type: 77,
				item: {},
				storeInfo: {},
				dialogTitle: "",
				dialogType: '',
				dialogVisible: false,
				dialogloading: false,
				closeModal: false, //是否点击弹框 关闭
			};
		},
		created() {
			if (this.$route.query.id) {
				this.id = this.$route.query.id;
			}
			if (this.$route.query.money) {
				this.money = this.$route.query.money;
			}
			if (this.$route.query.state) {
				this.state = this.$route.query.state;
			}
			this.uploadData={
				username: local.get('bpcusername'),
				tid: local.get('bpctid'),
				store_id:this.id,
				login_type:2,
			};
			this.getStoreMoneyGroup()
			this.storeQrList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			getStoreMoneyGroup() {
				this.$api.getStoreMoneyGroup({
					store_id: this.id
				}).then(res => {
					if (res.status == 1) {
						res.data.splice(0, 0, '全部')
						this.moneyArr = JSON.parse(JSON.stringify(res.data))

					}
				})
			},
			getData() {
				this.storeQrList()
			},
			storeQrList() {
				let data = {
					store_id: this.id,
					type: this.state,
					money: this.money,
					page: this.page,
					size: this.pageSize,
				}
				this.disLoading = true
				this.$api.storeQrList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.tableData = res.data.data;
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.page = 1
				this.storeQrList()
			},
			handleCurrentChange(val) {
				this.page = val
				this.storeQrList()
			},
			handleSelectionChange(val) {
				// console.log(val)
				this.multipleSelection = val;
			},
			deleteAll() {
				let selectArr = this.multipleSelection.filter(item => {
					if (item.id) {
						return item
					}
				})
				let dataId = selectArr.map(item => {
					return item.id
				})
				let data = {
					qr_id: dataId.join(',')
				}
				console.log(data)
				this.deleteStoreQr(data)
			},
			stateAll(state) {
				let selectArr = this.multipleSelection.filter(item => {
					if (item.id) {
						return item
					}
				})
				let dataId = selectArr.map(item => {
					return item.id
				})
				let data = {
					state: state,
					qr_id: dataId.join(',')
				}
				console.log(data)
				this.setStoreQrState(data)
			},
			deleteQr(item) {
				let data = {
					qr_id: item.id
				}
				console.log(data)
				this.deleteStoreQr(data)
			},
			setState(item) {
				let data = {
					state: item.state == 1 ? 0 : 1,
					qr_id: item.id
				}
				console.log(data)
				this.setStoreQrState(data)
			},
			setStoreQrState(data) {
				this.$confirm(`确定改变选择收款码的启停状态吗?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.operating = true
					console.log('==============')
					this.$api.setStoreQrState(data).then(res => {
						console.log("res")
						this.operating = false
						if (res.status == 1) {
							this.storeQrList()
						} else {
							this.errorArr = res.data
						}
					}).catch(error => {
						this.operating = false
					})
				}).catch(() => {
					this.operating = false
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			deleteStoreQr(data) {
				this.$confirm(`确定删除选择收款码吗?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.operating = true
					this.$api.deleteStoreQr(data).then(res => {
						this.operating = false
						if (res.status == 1) {
							this.storeQrList()
						} else {
							this.errorArr = res.data
						}
					}).catch(error => {
						this.operating = false
					})
				}).catch(() => {
					this.operating = false
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			showQr(url) {
				this.showPopup = true
				document.getElementById('qr').innerHTML = '';
				var qrcode = new QRCode(this.$refs.qrCodeUrl, {
					text: url, // 需要转换为二维码的内容
					width: 400,
					height: 400,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			},
			closePopup() {
				this.showPopup = false
			},
			newqr() {
				
				let queryData = {}
				queryData['id'] = this.id
				this.$router.push({
					path: '/passageAlisub2',
					query: queryData,
				})
				// this.dialogType = 'new'
				// this.dialogTitle = "新增"
				// this.dialogVisible = true
			},
			
			close() {
				this.dialogVisible = false
				this.dialogloading = false
				this.dialogTitle = ''
				this.dialogType = ""
				this.item = {}
				this.storeInfo = {}

			},
			edit(item){
				this.dialogType = 'show'
				this.dialogTitle = '编辑'
				this.item = JSON.parse(JSON.stringify(item)) 
				this.dialogVisible = true
				
			},
			sure() {
					let data = {
						qr_id: this.item.id,
						note: this.item.note,
					}
					this.$api.editStoreQr(data).then(res => {
						if (res.status == 1) {
							this.close()
							this.storeQrList()
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				
			},

		},
		watch: {

			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - threeDirectoryBox - 40
				})
			}
		},

	};
</script>

<style scoped="scoped" lang="scss">
	@import '@/style/variables.scss';

	.dataKey {
		display: inline-block;
		flex: 0 0 100px;
		text-align: left;
	}

	.qrIcon {
		display: inline-block;
		background: url(../../assets/image/qr.png);
		width: 30px;
		height: 30px;
		background-size: 100% 100%;
	}

	.errorDiv {
		white-space: nowrap;
		z-index: 2;
		position: absolute;
		top: 0;
		font-size: 10px;
		color: $danger
	}
	#uploadImg{
		display: none;
	}
	.popup {
		position: fixed;
		left: 0;
		top: 0;
		display: none;
		background: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		z-index: 9999;

		&.show {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.qrcode {
			display: inline-block;

			img {
				width: 400px;
				height: 400;
				background-color: #fff; //设置白色背景色
				padding: 20px; // 利用padding的特性，挤出白边
				box-sizing: border-box;
			}
		}
	}
</style>
